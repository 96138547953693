import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Navbar() {
    let NavTextColor = useQuery().get("page") === null ? 'white' : 'white'
    const { t, i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    return (
        <nav className="navbar">
            <div className="container-fluid">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        style={{ borderColor: "#e7e7e7" }} data-target="#ac-nav" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar" style={{ backgroundColor: "#888" }}></span>
                        <span className="icon-bar" style={{ backgroundColor: "#888" }}></span>
                        <span className="icon-bar" style={{ backgroundColor: "#888" }}></span>
                    </button>
                    <Link to="/" className="textHover" style={{ color: NavTextColor, textDecoration: 'none' }}>
                        <img src="./images/ac_cut.png" className="navbar-brand" alt="alogo"></img>
                    </Link>
                </div>

                <div className="collapse navbar-collapse stroke" id="ac-nav">
                    <ul className="nav navbar-nav">
                        <li><Link to="/" className="textHover" style={{ color: NavTextColor, textDecoration: 'none' }}>{t('home')}</Link></li>
                        <li><Link to="/landing?page=services" className="textHover" style={{ color: NavTextColor, textDecoration: 'none' }}>{t('services')}</Link></li>
                        <li className="dropdown">
                            <a className="textHover dropdown-toggle" style={{ color: NavTextColor }} type="button" id="languagesDropdown" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="true" href="/#">
                                <span >{t('languages')}</span></a>
                            <ul className="dropdown-menu" aria-labelledby="languagesDropdown">
                                {/* eslint-disable-next-line */}
                                <li><a type="button" style={{ color: NavTextColor, cursor: 'pointer' }} onClick={() => changeLanguage('en')}>English</a></li>
                                {/* eslint-disable-next-line */}
                                <li><a type="button" style={{ color: NavTextColor, cursor: 'pointer' }} onClick={() => changeLanguage('fr')}>French</a></li>
                                {/* eslint-disable-next-line */}
                                <li><a type="button" style={{ color: NavTextColor, cursor: 'pointer' }} onClick={() => changeLanguage('it')}>Italian</a></li>
                                {/* eslint-disable-next-line */}
                                <li><a type="button" style={{ color: NavTextColor, cursor: 'pointer' }} onClick={() => changeLanguage('es')}>Spanish</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
