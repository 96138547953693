import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            "home": "Home",
            "services": "Services",
            "languages": "Language",
            "info": "Info",
            "contact-us": "Contact Us",
            "about-us": "About us",
            "careers": "Careers",
            "work-with-us": "Work with us",
            "more": "More",
            "our-enterprise": "Our Enterprise",
            "our-enterprise-text": "Born in 2017, AC Construction is a company nowadays made up of a team of more than 50 construction and architecture professionals",
            "our-enterprise-landing-1": "The demand for work by our customers has contributed to the evolution of\
                                        the company and its growth to become a society capable of offering you the\
                                        complete administration of your project, be it a new construction,\
                                        complete or partial renovation, or a transformation.",
            "our-enterprise-landing-2": "From the beginning of the study to the end, we will support you at every\
                                        stage of your project, taking care to respect your ideas,\
                                        your expectations and your budget.",
            "our-enterprise-landing-3": "Based in Geneva, we operate in the entire Lake Léman basin but also in the\
                                        rest of Switzerland, wherever our clients request us.",
            "our-enterprise-landing-4": "The strength of the company AC Construcción is based on the composition of\
                                        its team of professionals, which gathers internally all the trades necessary\
                                        to study and carry out your project..",
            "notre-divise": "Our slogan, a quality work",
            "notre-divise-text": "Whether it is new construction or renovation, the company ALFA CONSTRUCTIONS Sàrl is at your disposal to make your dreams reality.",
            "nos-atouts": "Our strengths",
            "nos-atouts-text": "Compliance with requirements in a quality approach and attention to detail",
            "nos-atouts-landing-1": "Compliance with requirements in a quality approach and attention to detail",
            "nos-atouts-landing-2": "Respect for the real cost of the project",
            "nos-atouts-landing-3": "Optimization of operating procedures for the best implementation",
            "nos-atouts-landing-4": "We respect deadlines",
            "nos-atouts-landing-5": "The works are carried out by our best qualified workers and craftsmen",
            "nos-atouts-landing-6": "Special attention to safety on construction sites",
            "services-text": "Whether it is new construction or renovation, the company ALFA CONSTRUCTIONS Sàrl is at your disposal for the following services:",
            "services-landing-1": "Reinforced concrete",
            "services-landing-2": "Asbestos removal",
            "services-landing-3": "Plastering",
            "services-landing-4": "Installation of plasterboard partitions",
            "services-landing-5": "Tiling",
            "services-landing-6": "Demolitions",
            "services-landing-7": "Parquet",
            "services-landing-8": "Carpets and paintings",
            "services-landing-text": "Equally applicable for private clients, institutions or communities,\
                                regardless of the size of the property",
            "study-phase-title": "Study Phase",
            "study-phase-text": "During this phase, we offer complete solutions that determine the main elements of the project. This is an essential step, it allows having a concrete vision of the technical, financial and delivery deadlines. During this phase we can share your ideas, your wishes, expectations, and much more.",
            "realization-phase-title": "Implementation Phase",
            "realization-phase-text": "Our experience in the management and execution of the work allows us to implement all the necessary means for the elaboration of the project. We are committed to coordinating and carrying out the project in the best conditions and within a specific time. During implementation, communication is an important element and we guarantee the presence of the project manager throughout the implementation phase so that you can give your personal touch and participate in decision-making. Because the difference is sometimes measured in the smallest details.",
            "commercial-phase-title": "Implementation in commercial spaces",
            "commercial-phase-text": "For many years we have supported our clients in the creation of their commercial spaces. Our intervention in professional or commercial environments requires special attention in terms of security and confidentiality. We can provide solutions while carrying out on-site work to reduce discomfort and give our clients the opportunity to continue their activities in the best possible conditions. Respect for delivery deadlines, speed of execution and flexibility are elements that allow us to guarantee total customer satisfaction.",
            "private-phase-title": "Implementation in private spaces",
            "private-phase-text": "Renovations, expanding your home or apartment is an important step. Whether it is a complete renovation or a partial transformation, we offer you unique solutions, tailored to your needs and space. A totally personalized and customized project. Exteriors, Expand your living space and improve your exterior. We bring you adapted solutions for all types of outdoor work, terrace, pool, portal, etc."
        }
    },
    it: {
        translation: {
            "home": "Home",
            "services": "Servizi",
            "languages": "Lingua",
            "info": "Informazioni",
            "contact-us": "Contattaci",
            "about-us": "Su di noi",
            "careers": "Jobs",
            "work-with-us": "Lavora con noi",
            "more": "Info",
            "our-enterprise": "La Nostra Azienda",
            "our-enterprise-text": "Creata nel 2017, AC Construction è un'azienda al giorno d'oggi composta da un equipe di piú di 50 professionisti della costruzione e dell'architettura",
            "our-enterprise-landing-1": "I lavori effettuati ai nostri clienti hanno contribuito a far crescere\
                                        l'azienda e ad evolversi in una societá capace di offire l'amministrazione\
                                        completa del progetto, che sia una nuova costruzione, un rinnovamento completo o\
                                        parziale, o una trasformazione.",
            "our-enterprise-landing-2": "Dallo studio iniziale fino alla fine, vi aiuteremo \
                                        in ogni tappa del progetto, rispettando le idee,\
                                        aspettative e budget.",
            "our-enterprise-landing-3": "Con sede a Ginevra, operiamo non solo nella zona del lago Lemán,\
                                        persino nel resto della Svizzera o dove i nostri clienti richiedano.",
            "our-enterprise-landing-4": "La forza della societá AC-Construction viene dal nostro gruppo di \
                                        professionisti, che riunisce tutte le figure necessarie \
                                        per lo studio e la realizzazione del proyecto.",
            "notre-divise": "Il nostro motto: un lavoro di qualitá",
            "notre-divise-text": "Che si tratti di nuove costruzioni o ristrutturazioni, la società ALFA CONSTRUCTIONS Sàrl è a vostra disposizione per soddisfare i vostri desideri",
            "nos-atouts": "I nostri punti di forza",
            "nos-atouts-text": "Lavoriamo con qualità e attenzione ai dettagli",
            "nos-atouts-landing-1": "Esigenza, qualitá e attenzione ai dettagli",
            "nos-atouts-landing-2": "Rispettiamo il costo reale del progetto",
            "nos-atouts-landing-3": "Ottimiziamo i procedimenti operativi nel migliore dei modi",
            "nos-atouts-landing-4": "Rispettiamo i tempi di consegna",
            "nos-atouts-landing-5": "Realizziamo i lavori con i nostri ingegneri e artigiani piú qualificati",
            "nos-atouts-landing-6": "Diamo attenzione speciale alla sicurezza nei cantieri di costruzione",
            "services-text": "La societá ALFA CONSTRUCTIONS Sàrl mette a vostra disposizione per la realizzazione dei seguenti servizi:",
            "services-landing-1": "Cemento armato",
            "services-landing-2": "Eliminazione dell'amianto",
            "services-landing-3": "Lucidatura",
            "services-landing-4": "Installazione di cartongesso",
            "services-landing-5": "Piastrellatura",
            "services-landing-6": "Demolizione",
            "services-landing-7": "Parquet",
            "services-landing-8": "Moquette e pittura",
            "services-landing-text": "Tutto questo è applicabile sia ai privati, alle istituzioni o alle comunitá, indipendentemente della grandezza dell'ente",
            "study-phase-title": "Fase di studio",
            "study-phase-text": "Durante questa fase, offriamo soluzioni complete che definiscono gli elementi principali del progetto. È un momento fondamentale, che permette avere una visione concreta degli aspetti tecnici, finaziari e dei tempi di consegna. Durante questa fase è fondamentale condividare idee, desideri, aspettative, etc..",
            "realization-phase-title": "Fase di Realizzazione",
            "realization-phase-text": "La nostra esperienza nella gestione e realizzazione del lavoro ci permette inserire tutti i mezzi necessari per l'elaborazione del progetto. Ci compromettiamo a coordinare e realizzare il progetto dentro dei tempi stabiliti. Durante la realizzazione, la comunicazione è un elemente fondamentale, per questo garantiamo la presenza del responsabile del progetto affinche lei possa dare il suo tocco personale e partecipare alla presa delle decisioni. La differenza a volte è nei dettagli piú piccoli.",
            "commercial-phase-title": "Realizzazione di spazi commerciali",
            "commercial-phase-text": "Durante molti anni, abbiamo aiutato i nostri clienti nella realizzazione di spazi commerciali. I nostri interventi in ambienti professionali o commerciali necessitano di una attenzione speciale alla sicurezza e confidenzialitá, tutto questo per ridurre al minimo i problemi e dare ai nostri clienti l'opportunitá di continuare le attivitá nelle migliori condizioni possibili. Il rispetto dei tempi di consegna, la rapiditá d'esecuzione e la flessibilitá sono elementi che ci permettono garantire la soddisfazione totale dei nostri clienti.",
            "private-phase-title": "Realizzazione di spazi privati",
            "private-phase-text": "Rinnovare, espandere la casa o l'appartamento è un passo importante. Che si tratti di un rinnovamento o di una trasformazione parziale, offriamo soluzioni uniche, adattate alle necessitá e agli spazi. Un progetto totalmente personalizzato, su misura."
        }
    },
    fr: {
        translation: {
            "home": "Home",
            "services": "Services",
            "languages": "Langue",
            "info": "Information",
            "contact-us": "Nous contacter",
            "about-us": "À propos de nous",
            "careers": "Recrutement",
            "work-with-us": "Travaille avec nous",
            "more": "Plus",
            "our-enterprise": "Notre Société",
            "our-enterprise-text": "Créée en 2017, AC construction est une société aujourd’hui composée d’une équipe de plus de 50 professionnels de la construction et de l’architecture",
            "our-enterprise-landing-1": "Les demandes de nos clients ont contribué à l’évolution de \
                                        l’entreprise et à sa roissance pour devenir une société à même de \
                                        vous proposer la gestion complète de votre projet, qu’il s’agisse \
                                        d’une construction, d’une rénovation complète ou partielle, ou \
                                        d’une transformation.",
            "our-enterprise-landing-2": "De l’étude à la réalisation, nous vous accompagnons à chaque \
                                        étape de votre projet en prenant soin de respecter vos idées, vos \
                                        attentes et votre budget.",
            "our-enterprise-landing-3": "Basé à Genève, nous intervenons sur tout le bassin lémanique \
                                        mais également dans le reste de la suisse nos clients nous le \
                                        demandent.",
            "our-enterprise-landing-4": "La force de la société AC construction réside dans la composition \
                                        de son équipe de professionnels, qui en interne, regroupe \
                                        l’ensemble des corps de métier nécessaires à l’étude et à la \
                                        réalisation de votre projet.",
            "notre-divise": "Notre Devise, un travail de qualité",
            "notre-divise-text": "Qu'il s'agisse de construction neuve on de rénovation, la société ALFA CONSTRUCTIONS Sàrl est à vostre disposition pour répondre à vos envies",
            "nos-atouts": "Nos Atouts",
            "nos-atouts-text": "Respect des exigences dans une démarche de qualité et du souci du détail",
            "nos-atouts-landing-1": "Respect des exigences dans une démarche de qualité et du souci du détail",
            "nos-atouts-landing-2": "Respect du coût réel des travaux",
            "nos-atouts-landing-3": "Optimisation des modes opératoires de mise en œuvre",
            "nos-atouts-landing-4": "Respect des délais",
            "nos-atouts-landing-5": "Réalisation des travaux par nos ouvrier et artisans qualifiés",
            "nos-atouts-landing-6": "Une attention particulière portée à la sécurité sur les chantiers",
            "services-text":"Qu'il s'agisse de construction neuve ou de rénovation, la société ALFA CONSTRUCTIONS Sàrl est à votre disposition des travaux des secteur du second œuvre, incluant moaçonnerie:",
            "services-landing-1":"Béton armé",
            "services-landing-2":"Désamiantage",
            "services-landing-3":"Plâtrerie (gypserie)",
            "services-landing-4":"Pose de cloisons en placo plâtre",
            "services-landing-5":"Carrelage",
            "services-landing-6":"Démolition",
            "services-landing-7":"Parquet",
            "services-landing-8":"Moquette et peinture notamment",
            "services-landing-text":"Vaut autant pour des clients privés. Institutionnels ainsi que pour des collectivités Ceci indépendamment de la taille de bien immobilier.",
            "study-phase-title": "Phase d’étude",
            "study-phase-text": "Durant la phase d’étude, nous vous fournissons des solutions d’ensemble\
                                déterminant les éléments majeurs du projet.\
                                C’est une étape incontournable, elle permet d’avoir une vision concrète des\
                                aspects techniques, financiers et temporels.\
                                C’est lors de cette phase que nous faisons connaissance et que vous nous\
                                exposez vos idées, vos envies, vos rêves",
            "realization-phase-title": "Phase de réalisation",
            "realization-phase-text": "Notre expertise en matière de gestion et réalisation des travaux nous permet\
                                    de mettre en œuvre tous les moyens nécessaires à l’exécution du chantier.\
                                    Nous nous engageons à coordonner et réaliser les travaux dans les\
                                    meilleures conditions et dans un temps déterminé.\
                                    Lors de la réalisation, la communication est un élément important et la\
                                    présence du maître d’œuvre lors des rendez vous de chantier est primordiale,\
                                    et cela tout au long de la phase de réalisation afin d’apporter votre touche\
                                    personnelle et de participer aux prises de décision. Car la différence se\
                                    mesure parfois dans les plus infimes détails.",
            "commercial-phase-title": "Réalisation d'espaces commerciaux",
            "commercial-phase-text": "Depuis de nombreuses années, nous accompagnons nos clients dans\
                                    la réalisation de leurs espaces commerciaux.\
                                    Notre intervention dans des environnements professionnels ou\
                                    commerciaux nécessite des attentions particulières en matière de\
                                    sécurité et de confidentialité. Nous sommes en mesure d’apporter\
                                    des solutions lors de travaux effectués dans des locaux en activité\
                                    afin de limiter les nuisances et de donner la possibilité à nos clients\
                                    de continuer leurs activités dans les meilleures conditions possibles.\
                                    Le respect des délais, la rapidité d’exécution et la flexibilité sont des\
                                    éléments qui nous permettent de garantir la totale satisfaction de nos\
                                    clients.",
            "private-phase-title": "Réalisation d'espaces privés",
            "private-phase-text": "Rénover, agrandir votre maison ou votre appartement est une étape\
            importante. Qu’il s’agisse d’une rénovation complète ou d’une transformation\
            partielle, nous vous proposons des solutions uniques, adaptées à vos\
            besoins et à votre espace. Un projet entièrement personnalisé et sur mesure.\
            IN &amp; OUT\
            Prolonger votre espace de vie et valoriser votre extérieur.\
            Nous vous amenons des solutions adaptées pour tous les types de travaux\
            d’extérieur, terrasse, piscine,\
            Portail, etc…"
        }
    },
    es: {
        translation: {
            "home": "Inicio",
            "services": "Servicios",
            "languages": "Idioma",
            "info": "Información",
            "contact-us": "Contáctanos",
            "about-us": "Sobre nosotros",
            "careers": "Empleo",
            "work-with-us": "Trabaja con nosotros",
            "more": "Más",
            "our-enterprise": "Nuestra Empresa",
            "our-enterprise-text": "Creada en 2017, AC Construction es una empresa que a día de hoy está formada por más de 50 profesionales de la construcción y arquitectura",
            "our-enterprise-landing-1": "La demanda de trabajo por parte de nuestros clientes ha contribuido a la evolución\
                                        de la empresa y su crecimiento para convertirse en una sociedad capaz de ofrecerle\
                                        la administración completa de su proyecto, ya sea\
                                        una construcción nueva, renovación completa o parcial, o\
                                        una transformación.",
            "our-enterprise-landing-2": "Desde el inicio del estudio hasta la finalización, le apoyaremos\
                                        en cada etapa de su proyecto, cuidando respetar sus ideas,\
                                        sus expectativas y su presupuesto.",
            "our-enterprise-landing-3": "Con sede en Ginebra, operamos en toda la cuenca del lago de Léman\
                                        pero también en el resto de Suiza, allá donde nuestros clientes nos soliciten.",
            "our-enterprise-landing-4": "La fuerza de la sociedad AC Construction radica en la composición de su equipo\
                                        de profesionales, que reúne internamente todos los oficios necesarios\
                                        para el estudio y la realización de su proyecto.",
            "notre-divise": "Nuestro lema, un trabajo de calidad",
            "notre-divise-text": "Ya sea una construcción nueva o una renovación, la sociedad ALFA CONSTRUCTIONS Sàrl está a vuestra disposición para hacer vuestros sueños realidad",
            "nos-atouts": "Nuestros puntos fuertes",
            "nos-atouts-text": "Cumplimos con exigencia, calidad y atención al detalle",
            "nos-atouts-landing-1": "Cumplimos con exigencia, calidad y atención al detalle",
            "nos-atouts-landing-2": "Respeto por el coste real del proyecto",
            "nos-atouts-landing-3": "Optimización de los procedimientos operativos para la mejor implementación",
            "nos-atouts-landing-4": "Respetamos los plazos de entrega",
            "nos-atouts-landing-5": "Los trabajos son realizados por nuestros obreros y artesanos mejor cualificados",
            "nos-atouts-landing-6": "Atención especial a la seguridad en obras de construcción",
            "services-text": "Ya sea una construcción nueva o una renovación, la sociedad ALFA CONSTRUCTIONS Sàrl está a vuestra disposición para la realización de los siguientes trabajos:",
            "services-landing-1":"Hormigón armado",
            "services-landing-2":"Eliminación de amianto",
            "services-landing-3":"Enlucido",
            "services-landing-4":"Instalación de tabiques de pladur",
            "services-landing-5":"Baldosas",
            "services-landing-6":"Demoliciones",
            "services-landing-7":"Parquet",
            "services-landing-8":"Moquetas y pinturas",
            "services-landing-text":"Igualmente aplicable para clientes privados, Instituciones o para comunidades, independientemente del tramaño de la propiedad",  
            "study-phase-title": "Fase de estudio",
            "study-phase-text": "Durante esta fase, le ofreceremos soluciones completas que determinen los elementos principales del proyecto. Este es un paso esencial, permite tener una visión concreta de los aspectos técnicos, financieros y de plazos de entrega. Durante esta fase nos podrá contar sus ideas, sus deseos, expectativas, y mucho más...",
            "realization-phase-title": "Fase de realización",
            "realization-phase-text": "Nuestra experiencia en la gestión y ejecución del trabajo nos permite implementar todos los medios necesarios para la elaboración del proyecto. Estamos comprometidos a coordinar y llevar a cabo el proyecto en las mejores condiciones y dentro de un tiempo específico. Durante la realización, la comunicación es un elemento importante y garantizamos la presencia del gerente del proyecto a lo largo de la fase de realización para que pueda usted brindar su toque personal y participar en la toma de decisiones. Porque la diferencia, a veces, se mide en los detalles más pequeños.",
            "commercial-phase-title": "Realización de espacios comerciales",
            "commercial-phase-text": "Durante muchos años hemos apoyado a nuestros clientes en la creación de sus espacios comerciales. Nuestra intervención en entornos profesionales o comerciales requiere una atención especial en términos de seguridad y confidencialidad. Podemos proporcionar soluciones durante la realización del trabajo en las instalaciones para reducir las molestias y dar a nuestros clientes la oportunidad de continuar sus actividades en las mejores condiciones posibles. El respeto de los plazos de entrega, la rapidez de ejecución y la flexibilidad son elementos que nos permiten garantizar la satisfacción total de nuestros clientes.",
            "private-phase-title": "Realización de espacios privados",
            "private-phase-text": "Renovar, expandir su casa o su apartamento es un paso importante. Ya sea una renovación completa o una transformación parcial, le ofrecemos soluciones únicas, adaptadas a sus necesidades y su espacio. Un proyecto totalmente personalizado y a medida. Exteriores, Amplíe su espacio vital y mejore su exterior. Le traemos soluciones adaptadas para todo tipo de trabajo en exterior, terraza, piscina, portal, etc."
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "fr",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;