import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    return (
        <footer id="myFooter">
            <div className="container">
                <div className="row">
                    <div className="col-sm-3">
                        <h2 className="logo"><img src="./images/ac_cut.png" style={{ height: "110px" }} alt="AC"></img></h2>
                    </div>
                    <div className="col-sm-2 footer-ul">
                        <h5>{t('about-us')}</h5>
                        <ul>
                            <li><a href="/#">{t('info')}</a></li>
                            <li><a href="/#">{t('contact-us')}</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-2 footer-ul">
                        <h5>{t('careers')}</h5>
                        <ul>
                            <li><a href="/#">{t('work-with-us')}</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <div className="social-networks">
                            <a href="/#" className="twitter"><i className="fa fa-twitter"></i></a>
                            <a href="/#" className="facebook"><i className="fa fa-facebook"></i></a>
                            <a href="/#" className="google"><i className="fa fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <p>AC-Construction © 2020</p>
            </div>
        </footer>
    );
}

export default Footer;
