import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import Footer from './Footer';
import NoMatch from './NoMatch';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function NewLanding() {
    const { t } = useTranslation();
    let query = useQuery();

    switch (query.get("page")) {
        case "notre-societe":
            return (
                <div>
                    <div className="notre-societe-bg" style={{ height: "100vh", width: "100vw", zIndex: "-1" }}></div>
                    <figure className="snip1401 hover">
                        <figcaption>
                            <div className="landing-content">
                                <h1 style={{textAlign:'center'}}>{t('our-enterprise')}</h1>
                                <h4 style={{ marginTop: "20px", lineHeight: "normal", textAlign: "left" }}>
                                    {t('our-enterprise-landing-1')}
                                    <br></br><br></br>
                                    {t('our-enterprise-landing-2')}
                                    <br></br><br></br>
                                    {t('our-enterprise-landing-3')}
                                    <br></br><br></br>
                                    {t('our-enterprise-landing-4')}
                                </h4>
                            </div>
                        </figcaption>
                    </figure>
                    <Footer />
                </div>
            );
        case "nos-atouts":
            return (
                <div>
                    <div className="notre-societe-bg" style={{ height: "100vh", width: "100vw", zIndex: "-1" }}></div>
                    <figure className="snip1401 hover">
                        <figcaption>
                            <div className="landing-content">
                                <h1 style={{textAlign:'center'}}>{t('nos-atouts')}</h1>
                                <h4 style={{ marginTop: "20px", lineHeight: "normal", textAlign: "left" }}>
                                    <li>{t('nos-atouts-landing-1')}</li>
                                    <li>{t('nos-atouts-landing-2')}</li>
                                    <li>{t('nos-atouts-landing-3')}</li>
                                    <li>{t('nos-atouts-landing-4')}</li>
                                    <li>{t('nos-atouts-landing-5')}</li>
                                    <li>{t('nos-atouts-landing-6')}</li>
                                </h4>
                            </div>
                        </figcaption>
                    </figure>
                    <Footer />
                </div>
            );
        case "services":
            return (
                <div>
                    <div className="notre-societe-bg" style={{ height: "100vh", width: "100vw", zIndex: "-1" }}></div>
                    <figure className="snip1401 hover">
                        <figcaption>
                            <div className="landing-content">
                                <h1 style={{textAlign:'center'}}>{t('services-text')}</h1>
                                <h4 style={{ marginTop: "20px", lineHeight: "normal", textAlign: "left" }}>
                                    <li>{t('services-landing-1')}</li>
                                    <li>{t('services-landing-2')}</li>
                                    <li>{t('services-landing-3')}</li>
                                    <li>{t('services-landing-4')}</li>
                                    <li>{t('services-landing-5')}</li>
                                    <li>{t('services-landing-6')}</li>
                                    <li>{t('services-landing-7')}</li>
                                    <li>{t('services-landing-8')}</li>
                                </h4>
                                <h4 style={{textAlign:'center'}}>{t('services-landing-text')}</h4>
                            </div>
                        </figcaption>
                    </figure>
                    <Footer />
                </div>
            );
        case "notre-devise":
            return (
                <div>
                    <div className="notre-societe-bg" style={{ height: "100vh", width: "100vw", zIndex: "-1" }}></div>
                    <figure className="snip1401 hover">
                        <figcaption>
                            <div className="landing-content" id="landing-content">
                                <h1 style={{textAlign:'center'}}>{t('notre-divise')}</h1>
                                <div className="panel" style={{display:'grid',boxShadow:'none',margin:'0',border:'0',width:'100%'}}>
                                    <button
                                        className="btn btn-primary"
                                        style={{ outline: 'none', fontWeight: 'bold', fontSize: '20px' }}
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#studyPhase"
                                        data-parent="#landing-content"
                                        aria-expanded="false"
                                        aria-controls="studyPhase">
                                        {t('study-phase-title')}
                                        <i className="fa fa-angle-down"></i>
                                    </button>
                                    <div className="collapse in" id="studyPhase">
                                        <div className="card card-body" style={{ textAlign: 'left', padding: '10px' }}>
                                            {t('study-phase-text')}
                                        </div>
                                    </div>

                                    <button
                                        className="btn btn-primary"
                                        style={{ outline: 'none', fontWeight: 'bold', fontSize: '20px' }}
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#realizationPhase"
                                        data-parent="#landing-content"
                                        aria-expanded="false"
                                        aria-controls="realizationPhase">
                                        {t('realization-phase-title')}
                                        <i className="fa fa-angle-down"></i>
                                    </button>
                                    <div className="collapse" id="realizationPhase">
                                        <div className="card card-body" style={{ textAlign: 'left', padding: '10px' }}>
                                            {t('realization-phase-text')}
                                        </div>
                                    </div>

                                    <button
                                        className="btn btn-primary"
                                        style={{ outline: 'none', fontWeight: 'bold', fontSize: '20px' }}
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#commercialPhase"
                                        data-parent="#landing-content"
                                        aria-expanded="false"
                                        aria-controls="commercialPhase">
                                        {t('commercial-phase-title')}
                                        <i className="fa fa-angle-down"></i>
                                    </button>
                                    <div className="collapse" id="commercialPhase">
                                        <div className="card card-body" style={{ textAlign: 'left', padding: '10px' }}>
                                            {t('commercial-phase-text')}
                                        </div>
                                    </div>

                                    <button
                                        className="btn btn-primary"
                                        style={{ outline: 'none', fontWeight: 'bold', fontSize: '20px' }}
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#privatePhase"
                                        data-parent="#landing-content"
                                        aria-expanded="false"
                                        aria-controls="privatePhase">
                                        {t('private-phase-title')}
                                        <i className="fa fa-angle-down"></i>
                                    </button>
                                    <div className="collapse" id="privatePhase">
                                        <div className="card card-body" style={{ textAlign: 'left', padding: '10px' }}>
                                            {t('private-phase-text')}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </figcaption>
                    </figure>
                    <Footer />
                </div>
            );
        default:
            return (
                <NoMatch />
            );
    }

}

export default NewLanding;
