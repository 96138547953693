import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from './Footer';

function NoMatch() {
    const { t } = useTranslation();
    return (
        <div>
            <div className="no-match" style={{ height: "100vh", width: "100vw" }}>
                <div className="container">
                    <div className="row not-found-text">
                        <div className="col-lg-12" style={{ textAlign: "center" }}>page not found</div>
                        <div className="col-lg-12" style={{ textAlign: "center" }}><Link to="/" style={{ textDecoration: 'none' }}><button className="btn btn-primary" type="button">{t('home')}</button></Link></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default NoMatch;
