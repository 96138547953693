import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Footer from './Footer';

function Carousel() {
    const { t } = useTranslation();
    return (
        <div>
            <div id="acCarousel" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#acCarousel" data-slide-to="0" className="active"></li>
                    <li data-target="#acCarousel" data-slide-to="1"></li>
                    <li data-target="#acCarousel" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="item bg bg1 active">
                        <div className="container">
                            <div className="carousel-caption">
                                <img src="./images/ac_logo.png" className="central-logo" alt="alogo"></img>
                                <h1>{t('our-enterprise')}</h1>
                                <p>{t('our-enterprise-text')}</p>
                                <p>
                                    <Link className="btn btn-lg btn-primary" to="/landing?page=notre-societe">
                                        {t('more')}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="item bg bg2">
                        <div className="container">
                            <div className="carousel-caption">
                                <img src="./images/ac_logo.png" className="central-logo" alt="alogo"></img>
                                <h1>{t('notre-divise')}</h1>
                                <p>
                                    {t('notre-divise-text')}
                                </p>
                                <p>
                                    <Link className="btn btn-lg btn-primary" to="/landing?page=notre-devise" role="button">
                                        {t('more')}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="item bg bg3">
                        <div className="container">
                            <div className="carousel-caption">
                                <img src="./images/ac_logo.png" className="central-logo" alt="alogo"></img>
                                <h1>{t('nos-atouts')}</h1>
                                <p>{t('nos-atouts-text')}</p>
                                <p>
                                    <Link className="btn btn-lg btn-primary" to="/landing?page=nos-atouts" role="button">
                                        {t('more')}
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="left carousel-control" href="#acCarousel" data-slide="prev">
                    <span
                        className="glyphicon glyphicon-chevron-left">
                    </span>
                </a>
                <a className="right carousel-control" href="#acCarousel" data-slide="next">
                    <span
                        className="glyphicon glyphicon-chevron-right">
                    </span>
                </a>
            </div>
            <Footer />
        </div>
    );
}

export default Carousel;
